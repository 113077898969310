import React from 'react'
import { Box, Typography, TextField, Button } from '@material-ui/core'
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { doLogin } from '../../../store/slices/authInfoSlice';
import { z } from 'zod';
import { maskEmail2, maskPhone } from 'maskdata'
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import styles from './styles.module.css';
import { TokenResponse } from '../../../models/AuthModels';
import { useHistory } from 'react-router-dom';
import { applicationsPath } from '../../../utils/paths';

export enum TWO_FA {
  EMAIL = 1,
  SMS,
  EMAIL_SMS
}

interface TwoFAFormProps {
  username: string
}

const TwoFAForm = (props: TwoFAFormProps) => {
  const { username } = props;
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const message = React.useMemo(() => t('insertOTPCode'), [t]);
  const emailMaskOptions = React.useMemo(() => {
    return {
      maskWith: "*",
      unmaskedStartCharactersBeforeAt: 3,
      unmaskedEndCharactersAfterAt: 5,
      maskAtTheRate: false
    }
  }, []);
  const phoneMaskOptions = React.useMemo(() => {
    return {
      maskWith: "*",
      unmaskedStartDigits: 3,
      unmaskedEndDigits: 3
    }
  }, []);

  const [twoFaType, setTwoFaType] = React.useState<TWO_FA | null>(null);
  const [prompt, setPrompt] = React.useState<string>('');
  const [codice, setCodice] = React.useState<string>('');
  const [email, setEmail] = React.useState<string>(username);
  const [phoneNumber, setPhoneNumber] = React.useState<string>('');

  const token = useAppSelector(state => state.authInfo.token);
  const userLoginInfo = useAppSelector(state => state.authInfo.userLoginInfo);

  const login = async () => {
    try {
      const response = await dispatch(doLogin({
        username,
        verifyCode: codice
      }));

      const newToken = (response?.payload as TokenResponse).token;
      if (newToken?.length > 0) {
        history.push(applicationsPath);
      }
    } catch (error) {
    }
  };

  React.useEffect(() => {
    const validateEmail = z.string().email();
    let currentEmail = username;

    try {
      currentEmail = validateEmail.parse(currentEmail);
    } catch (err) {
      currentEmail = userLoginInfo?.emailAlternativo ?? '';
    } finally {
      setEmail(currentEmail);
    }
  }, [email, userLoginInfo?.emailAlternativo, userLoginInfo?.nomeUtente, username]);

  React.useEffect(() => {
    if (twoFaType && [TWO_FA.EMAIL_SMS, TWO_FA.SMS].includes(twoFaType) && userLoginInfo?.cellulare) {
      setPhoneNumber(userLoginInfo?.cellulare)
    }
  }, [twoFaType, userLoginInfo?.cellulare]);

  React.useEffect(() => {
    setTwoFaType(() => {
      if (token?.endsWith('EMAILSMS')) {
        return TWO_FA.EMAIL_SMS;
      } else if (token?.endsWith('EMAIL')) {
        return TWO_FA.EMAIL;
      } else if (token?.endsWith('SMS')) {
        return TWO_FA.SMS;
      }

      return null;
    })
  }, [token]);

  React.useEffect(() => {
    setPrompt(state => {
      let medium = '';

      switch (twoFaType) {
        case TWO_FA.EMAIL_SMS:
          medium = 'EMAIL o SMS';
          break;
        case TWO_FA.EMAIL:
          medium = 'EMAIL';
          break;
        case TWO_FA.SMS:
          medium = 'SMS';
          break;
        default:
          return state;
      }

      return message + ' ' + medium + ':';
    });
  }, [message, twoFaType]);

  return (
    <Box display='flex' flexDirection='column' justifyContent='space-evenly'>
      <Box>
        <Typography className={clsx(styles.promptMessage)}>
          {prompt}
        </Typography>
        {
          // EMAIL
          twoFaType && [TWO_FA.EMAIL, TWO_FA.EMAIL_SMS].includes(twoFaType) && email &&
          <Typography className={clsx(styles.phoneEmail)}>{maskEmail2(email, emailMaskOptions)}</Typography>
        }
        {
          // CELLULARE
          twoFaType && [TWO_FA.SMS, TWO_FA.EMAIL_SMS].includes(twoFaType) && phoneNumber &&
          <Typography className={clsx(styles.phoneEmail)}>{maskPhone(phoneNumber, phoneMaskOptions)}</Typography>
        }
      </Box>
      <TextField
        fullWidth
        placeholder={t('otpCode')}
        inputProps={{
          maxLength: 6,
          style: {
            textAlign: 'center'
          }
        }}
        onChange={(e) => {
          setCodice(e.target.value);
        }}
      />
      <Button
        variant='contained'
        color='primary'
        onClick={login}
      >
        {t('verify')}
      </Button>
    </Box>
  )
}

export default TwoFAForm;
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import {
  checkJsonWebToken,
  fetchRefreshToken,
  isLogged,
  logoutAction,
} from "../store/slices/authInfoSlice";
import { fetchNomeHostInfo } from "../store/slices/nomeHostSlice";
import { cookieCheckTime, cookiePortal, refreshTokenTime } from "../utils/utilconst";
import { getCookie } from "../utils/utilfunctions";

const AppLoad = () => {
  const [timer, setTimer] = useState<any>(null);
  const [timer2, setTimer2] = useState<any>(null);

  const dispatch = useAppDispatch();
  const logged = useAppSelector(isLogged);

  useEffect(() => {
    var PERMITTED_DOMAIN = "https://gestioneturni.consorzioinnovotest.it";
    window.addEventListener("message", function (event) {
      if (event.origin === PERMITTED_DOMAIN) {
        //var msg = JSON.parse(event.data);
        // var msgKey = Object.keys(msg)[0];
        if (event.data) {
          localStorage.setItem("localstorage", JSON.stringify(event.data));
        } else {
          localStorage.removeItem("localstorage");
        }
      }
    });
  }, []);

  /*Al caricamento iniziale se è presente il token jwt nei cookie controlla che è ancora valido*/
  useEffect(() => {
    // se il path è /login, lascio al componente PageLogin il controllo del token
    const regexLogin = /login/g;
    if (!regexLogin.test(window.location.pathname)) {
      const token = getCookie(cookiePortal);
      token && !regexLogin.test(window.location.pathname) && dispatch(checkJsonWebToken(token));
    }
    dispatch(fetchNomeHostInfo());
  }, [dispatch]);

  /*Al login inizializza un interval che effettua il controllo della presenza del jwt nei cookie ogni 3 secondi*/
  useEffect(() => {
    if (logged) {
      setTimer(
        setInterval(function () {
          const token = getCookie(cookiePortal);
          if (!token) dispatch(logoutAction());
        }, cookieCheckTime)
      );
    }
  }, [logged, dispatch]);

  /*Al login inizializza un interval che effettua il refresh del token ogni refreshTokenTime millisecondi*/
  useEffect(() => {
    if (logged) {
      setTimer2(
        setInterval(() => {
          dispatch(fetchRefreshToken());
        }, refreshTokenTime)
      );
    }
  }, [logged, dispatch]);

  useEffect(() => {
    if (!logged) {
      clearInterval(timer);
      clearInterval(timer2);
    }
  }, [logged, timer, timer2]);

  return <></>;
};
export default AppLoad;

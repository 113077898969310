import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AuthRequest } from '../../models/SpidModels';
import { StatusEnum } from '../../models/Utils';
import { fetchAuthRequestCie as fetchAuthRequestCieSvc } from '../../services/authentication.service';

interface CieSliceState {
  status: string,
  authRequest: AuthRequest | null,
  error: string | null
}

const initialState: CieSliceState = {
  status: StatusEnum.Succeeded,
  authRequest: null,
  error: null
}

// @GetMapping(value = "auth/auth-cie")
export const fetchAuthRequestCie = createAsyncThunk('auth-cie', async (cieAssertionConsumerServiceIndex: number) => {
  const response = await fetchAuthRequestCieSvc(cieAssertionConsumerServiceIndex);
  return response.data as AuthRequest;
})

export const cieSlice = createSlice({
  name: 'cie',
  initialState,
  reducers: {
    clear: () => {
      return initialState;
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchAuthRequestCie.pending, (state) => {
      state.status = StatusEnum.Loading;
    })
    builder.addCase(fetchAuthRequestCie.rejected, (state, action) => {
      state.status = StatusEnum.Failed;
      state.error = (action.error.message) ? action.error.message : "";
    })
    builder.addCase(fetchAuthRequestCie.fulfilled, (state, { payload }: PayloadAction<AuthRequest>) => {
      state.status = StatusEnum.Succeeded;
      state.error = null;
      state.authRequest = payload;
    })
  }
})

export const { clear } = cieSlice.actions;
export default cieSlice.reducer;
import React, { Dispatch, SetStateAction } from 'react';
import { Backdrop, Fade, Modal as MuiModal, Paper, Theme, createStyles, makeStyles, Grid, IconButton, Box } from "@material-ui/core";
import { Close as CloseIcon } from '@material-ui/icons';
import clsx from 'clsx';

interface ModalProps {
  openState: {
    open: boolean,
    setOpen?: Dispatch<SetStateAction<boolean>>
  };
  handleClose?: () => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
    // Paper Modal
    dimension: {
      height: 500,
      width: 500
    },
  }),
);

const GTModal = (props: React.PropsWithChildren<ModalProps>) => {
  const { children, openState, handleClose: _handleClose } = props;

  const classes = useStyles();

  const handleClose = _handleClose ?? (() => openState.setOpen && openState.setOpen(false));

  return (
    <MuiModal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={openState.open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade in={openState.open}>
        <Paper elevation={2} className={clsx(classes.dimension)}>
          <Grid container direction='column'>
            <Grid item>
              <Box display='flex' justifyContent='flex-end'>
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </Grid>
            <Grid item>
              <Box display='flex' justifyContent='center' height={450}>
                {children}
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Fade>
    </MuiModal>
  )
}

export default GTModal
import Axios from "axios";
import { OrganizationInfo } from "../models/OrganizationModels";
import { NomeHost } from "../models/NomeHostModels";
import { portaleURL } from "../utils/utilconst";

export function organizationInfoLoad_old() {
    return Axios.get<OrganizationInfo>(portaleURL + '/organizzazioni/noauth/loginpageload/' + window.location.hostname);
}

export function nomeHostInfoLoad() {
    return Axios.get<NomeHost>(portaleURL + '/organizzazioni/noauth/loginpageload');
}
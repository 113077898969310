import { useEffect } from 'react';
import Axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { closeSnackBarError, closeSnackBarSuccess, openSnackBarError, openSnackBarSuccess } from '../../store/slices/snackBarSlice';
import { ErrorResponse, SimpleResponse } from '../../models/Utils';
import i18n from '../../i18n';


export const useInterceptor = () => {
  const dispatch = useAppDispatch();

  const onCloseError = () => {
    dispatch(closeSnackBarError());
  }

  const onCloseSuccess = () => {
    dispatch(closeSnackBarSuccess());
  }

  const token = useAppSelector(state => state.authInfo.token);

  useEffect(() => {
    let num: number;
    num = Axios.interceptors.request.use((config: InternalAxiosRequestConfig) => {

      if (token) {
        config.headers['Authorization'] = 'Bearer ' + token;
      }
      config.headers['Cache-Control'] = 'no-cache';
      config.headers['lang'] = i18n.language;

      return config;
    });

    return () => Axios.interceptors.request.eject(num);
  }, [token]);

  useEffect(() => {
    // handle errors
    Axios.interceptors.response.use((response: AxiosResponse<SimpleResponse>) => {
      if (response.data && 'message' in response.data) {
        dispatch(openSnackBarSuccess(response.data.message));
      }
      return response;
    }, function (error: AxiosError<ErrorResponse>) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      const message = (error.response && error.response.data.messaggio) ? error.response.data.messaggio : (error.response?.data.message ?? '');
      dispatch(openSnackBarError(message));
      return Promise.reject(message);
    });
  }, [dispatch]);

  return {
    onCloseError,
    onCloseSuccess
  }
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import cieSvg from './img/entra_con_cie.svg';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { fetchAuthRequestCie } from '../../../store/slices/cieSlice';
import { Theme, createStyles, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'center'
    }
  }),
);

interface CieAccessButtonProps {
  cieAssertionConsumerServiceIndex?: number
}

const Cie = (props: CieAccessButtonProps) => {
  const { cieAssertionConsumerServiceIndex } = props;

  const dispatch = useAppDispatch();
  const classes = useStyles();

  const samlAuthRequest = useAppSelector(state => state.cie.authRequest);

  const formRef = React.useRef<HTMLFormElement>(null);

  const authCie = React.useCallback(() => {
    if (cieAssertionConsumerServiceIndex)
      dispatch(fetchAuthRequestCie(cieAssertionConsumerServiceIndex))
  }, [cieAssertionConsumerServiceIndex, dispatch]);

  React.useEffect(() => {
    if (samlAuthRequest && formRef.current)
      formRef.current.submit();
  }, [samlAuthRequest]);

  return (
    <div className={clsx('container', classes.container)}>
      <a href='#' onClick={authCie}>
        <img
          src={cieSvg}
          alt="Entra con CIE"
          height={47.5}
        />
      </a>
      <div style={{ display: 'none' }}>
        {
          samlAuthRequest &&
          <form ref={formRef} method="POST" action={samlAuthRequest.destinationUrl}>
            <input type="hidden" name="SAMLRequest" value={btoa(samlAuthRequest.xmlAuthRequest)} />
            <input type="hidden" name="RelayState" value="kjhlsdhfk" />
            <input type="submit" value="Invia" />
          </form>
        }
      </div>
    </div>
  )
}

export default Cie
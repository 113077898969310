import Axios from "axios";
import { CheckJWTResponse, LoginRequest, TokenResponse } from "../models/AuthModels";
import { AuthRequest, SpidProviders } from "../models/SpidModels";
import { authURL } from "../utils/utilconst";

export function login(payload: LoginRequest) {
  return Axios.post<TokenResponse>(authURL + '', payload);
}

export function checkJWT(token: string) {
  const uninterceptedAxiosInstance = Axios.create();
  return uninterceptedAxiosInstance.get<CheckJWTResponse>(authURL + '/isvalid', { headers: { 'Authorization': 'Bearer ' + token } });
}

export function refreshToken() {
  return Axios.get<TokenResponse>(authURL);
}

// SPID
export function fetchAuthRequestSpid(entityId: string, assertionConsumerServiceIndex: number, testDomain?: string) {
  return Axios.get<AuthRequest>((testDomain ? testDomain : authURL) + '/auth-spid?entityId=' + entityId + '&assertionConsumerServiceIndex=' + assertionConsumerServiceIndex);
}

export function fetchSpidProvidersList() {
  return Axios.get<SpidProviders>(authURL + '/list-providers');
}

// CIE
export function fetchAuthRequestCie(assertionConsumerServiceIndex: number) {
  return Axios.get<AuthRequest>(authURL + '/auth-cie?assertionConsumerServiceIndex=' + assertionConsumerServiceIndex);
}
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import React, { useCallback, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { closeDialog } from '../../../store/slices/dialogsSlice';
import { useTranslation } from 'react-i18next';
import { resetPasswordLogged } from '../../../store/slices/userSlice';
import { emailIsValid, passwordIsValid } from '../../../utils/utilfunctions';
import { StatusEnum } from '../../../models/Utils';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) =>
    createStyles({
        padding: {
            padding: 24
        },
        closeButton: {
            position: 'absolute',
            right: 24,
            top: 24,
            color: theme.palette.grey[500]
        }
    })
);

export default function DialogLoggedResetPassword() {
    const classes = useStyles();
    const open = useAppSelector(state => state.dialogs.dialogState.open);
    const text = useAppSelector(state => state.dialogs.dialogState.text);
    const statusReset = useAppSelector(state => state.user.statusReset);
    const { i18n, t } = useTranslation();

    const [inputEmail, setInputEmail] = React.useState('');
    const [inputPassword, setInputPassword] = React.useState('');
    const [inputCheckPassword, setInputCheckPassword] = React.useState('');

    const [validation, setValidation] = React.useState({ invalidEmail: false, invalidPassword: false, differentPasswords: false });

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        switch (event.target.id) {
            case "email":
                validation.invalidEmail && setValidation(prev => { return { ...prev, invalidEmail: false } })
                setInputEmail(event.target.value);
                break;
            case "password":
                validation.invalidPassword && setValidation(prev => { return { ...prev, invalidPassword: false } })
                setInputPassword(event.target.value);
                break;
            case "checkpassword":
                validation.differentPasswords && setValidation(prev => { return { ...prev, differentPasswords: false } })
                setInputCheckPassword(event.target.value);
        }
    };

    const dispatch = useAppDispatch();

    const submitClick = () => {
        let valid = { invalidEmail: true, invalidPassword: true, differentPasswords: true };
        if (emailIsValid(inputEmail)) valid.invalidEmail = false;
        if (passwordIsValid(inputPassword)) valid.invalidPassword = false;
        if (inputPassword === inputCheckPassword) valid.differentPasswords = false;

        if (!valid.invalidEmail && !valid.invalidPassword && !valid.differentPasswords) {
            dispatch(resetPasswordLogged({ req: { nomeUtente: inputEmail, newPassword: inputPassword }, lang: i18n.language }));
        } else {
            setValidation(valid);
        }
    };

    const handleClose = useCallback(() => {
        dispatch(closeDialog());
        setInputEmail('');
        setInputPassword('');
        setInputCheckPassword('');
        setValidation({ invalidEmail: false, invalidPassword: false, differentPasswords: false });
    }, [dispatch]);

    useEffect(() => {
        if (statusReset === StatusEnum.Succeeded) {
            handleClose();
        }
    }, [statusReset, handleClose]);

    return (
        <Dialog onClose={handleClose} aria-labelledby="new-dialog-title" open={open && [undefined, null, ''].includes(text)}>
            <div className={classes.padding}>
                <IconButton size="small" aria-label="close" className={classes.closeButton} onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
                <Grid container justify="space-between" alignItems="flex-end" spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h5" id="new-dialog-title">
                            {t('resetPassword')}
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <TextField error={validation.invalidEmail} fullWidth id="email" label={t('emailAddress')} value={inputEmail} onChange={handleChange} />
                                <Typography variant="body2" color="error">{validation.invalidEmail && t('invalidEmail')}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField error={validation.invalidPassword} fullWidth id="password" type="password"
                                    label={t('newPassword')} value={inputPassword} onChange={handleChange} />
                                <Typography variant="body2" color="error">{validation.invalidPassword && t('invalidPassword')}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField error={validation.differentPasswords} fullWidth id="checkpassword" type="password"
                                    label={t('confirmPassword')} value={inputCheckPassword} onChange={handleChange} />
                                <Typography variant="body2" color="error">{validation.differentPasswords && t('differentPasswords')}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" onClick={submitClick} disabled={statusReset === StatusEnum.Loading}>{t('submit')}</Button>
                    </Grid>
                </Grid>
            </div>
        </Dialog>
    );
}


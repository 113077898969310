import { Button, createMuiTheme, createStyles, Grid, makeStyles, ThemeProvider, Typography, Dialog, Box } from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { closeDialog } from "../../../store/slices/dialogsSlice";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

const redSecondaryTheme = createMuiTheme({
  palette: {
    secondary: red,
  },
});

const useStyles = makeStyles((theme) =>
  createStyles({
    padding: {
      padding: 24
    },
    closeButton: {
      position: 'absolute',
      right: 24,
      top: 24,
      color: theme.palette.grey[500],
    },
  })
);

export default function DialogConfirm() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { open, text } = useAppSelector(state => state.dialogs.dialogState);

  const dispatch = useAppDispatch();

  const handleClose = useCallback(() => {
    dispatch(closeDialog());
  }, [dispatch]);

  return (
    <ThemeProvider theme={redSecondaryTheme}>
      <Dialog onClose={handleClose} aria-labelledby="goback-dialog-title" open={open && ![undefined, null, ''].includes(text)}>
        <div className={classes.padding}>
          <Grid container justify="space-between" alignItems="flex-end" spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" id="goback-dialog-title">
                {text}
              </Typography>
            </Grid>
            <Grid container item xs={12} justify='flex-end'>
              <Box>
                <Button fullWidth variant="contained" onClick={handleClose}>{t('close')}</Button>
              </Box>
            </Grid>
          </Grid>
        </div>
      </Dialog>
    </ThemeProvider>
  );
}


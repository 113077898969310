import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { LoggedUserResetPasswordRequest, NotLoggedUserResetPasswordRequest } from '../../models/UserModels';
import { StatusEnum } from '../../models/Utils';
import { loggeUserResetPassword, notLoggeUserResetPassword, userForgotPassword } from '../../services/users.service';

interface UserState {
  statusReset: StatusEnum,
  statusForgot: StatusEnum,
  error: string|null
}

const initialState: UserState = {
  statusReset: StatusEnum.Succeeded,
  statusForgot: StatusEnum.Succeeded,
  error: null
}

export const resetPasswordLogged = createAsyncThunk('user/resetPasswordLogged', async (param:{req:LoggedUserResetPasswordRequest, lang:string}) => {
  await loggeUserResetPassword(param.req, param.lang);
})

export const resetPasswordNotLogged = createAsyncThunk('user/resetPasswordNotLogged', async (param:{req:NotLoggedUserResetPasswordRequest, lang:string}) => {
  await notLoggeUserResetPassword(param.req, param.lang);
})

export const forgotPassword = createAsyncThunk('user/forgotPassword', async (param:{email:string, lang:string}) => {
  await userForgotPassword(param.email, param.lang);
})

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
  },
  extraReducers: builder => {
    builder.addCase(resetPasswordLogged.pending, (state) => {
      state.statusReset = StatusEnum.Loading;
    })
    builder.addCase(resetPasswordLogged.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusReset = StatusEnum.Failed;
    })
    builder.addCase(resetPasswordLogged.fulfilled, state => {
      state.statusReset = StatusEnum.Succeeded;
      state.error = null;
    })

    builder.addCase(resetPasswordNotLogged.pending, (state) => {
      state.statusReset = StatusEnum.Loading;
    })
    builder.addCase(resetPasswordNotLogged.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusReset = StatusEnum.Failed;
    })
    builder.addCase(resetPasswordNotLogged.fulfilled, state => {
      state.statusReset = StatusEnum.Succeeded;
      state.error = null;
    })

    builder.addCase(forgotPassword.pending, (state) => {
      state.statusForgot = StatusEnum.Loading;
    })
    builder.addCase(forgotPassword.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusForgot = StatusEnum.Failed;
    })
    builder.addCase(forgotPassword.fulfilled, state => {
      state.statusForgot = StatusEnum.Succeeded;
      state.error = null;
    })
  }
})

export default userSlice.reducer
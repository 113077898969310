import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";

const PageNotFound = () => {
    const { t } = useTranslation();
    return <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
    ><h1>{t('notFoundMessage')}</h1></Grid>
}
export default PageNotFound;
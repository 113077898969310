

import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { Language, LanguageEnum } from '../../models/Utils';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
    toggleBtn: {
        backgroundColor: '#ffffff'
    },
    flagImg: {
        maxHeight: 20
    }
}),
);

const LanguageToggle = () => {
    const classes = useStyles();
    const { i18n } = useTranslation();
    const [language, setLanguage] = useState<Language>(i18n.language as Language);

    const handleLanguageChange = useCallback((event: React.MouseEvent<HTMLElement>, lang: Language | null) => {
        if (lang) {
            i18n.changeLanguage(lang);
            setLanguage(lang);
        }
    }, [i18n]);

    useEffect(() => {
        (language === 'it-IT') && setLanguage(LanguageEnum.IT);
        (language === 'en-US') && setLanguage(LanguageEnum.EN);
    }, [language])
    
    return (
        <ToggleButtonGroup
            className={classes.toggleBtn}
            value={language}
            exclusive
            onChange={handleLanguageChange}
            aria-label="language"
        >
            <ToggleButton value={LanguageEnum.IT} aria-label="italian">
                <img className={classes.flagImg} src="./assets/flags/it-IT.png" alt="italian" />
            </ToggleButton>
            <ToggleButton value={LanguageEnum.EN} aria-label="english">
                <img className={classes.flagImg} src="./assets/flags/en-US.png" alt="english" />
            </ToggleButton>
        </ToggleButtonGroup>
    )
}
export default LanguageToggle;
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AuthRequest, ResponseDecoded, SpidProviders } from '../../models/SpidModels';
import { StatusEnum } from '../../models/Utils';
import {
  fetchAuthRequestSpid as fetchAuthRequestSpidSvc,
  fetchSpidProvidersList as fetchSpidProvidersListSvc
} from '../../services/authentication.service';

interface SpidSliceState {
  status: string,
  authRequest: AuthRequest | null,
  spidProviders: SpidProviders | null,
  responseDecoded: ResponseDecoded | null,
  error: string | null
}

const initialState: SpidSliceState = {
  status: StatusEnum.Succeeded,
  authRequest: null,
  spidProviders: null,
  responseDecoded: null,
  error: null
}

// https://domain/auth-spid/?entityId={entityID}&assertionConsumerServiceIndex={assertionConsumerServiceIndex}
export const fetchAuthRequestSpid = createAsyncThunk('auth-spid', async (obj: { entityId: string, assertionConsumerServiceIndex: number, testDomain?: string }) => {
  const response = await fetchAuthRequestSpidSvc(obj.entityId, obj.assertionConsumerServiceIndex, obj.testDomain);
  return response.data as AuthRequest;
});

// @GetMapping(value = "auth/list-providers")
export const fetchSpidProvidersList = createAsyncThunk('auth-spid-list', async () => {
  const response = await fetchSpidProvidersListSvc();
  return response.data as SpidProviders;
})

export const spidSlice = createSlice({
  name: 'spid',
  initialState,
  reducers: {
    clear: (state) => {
      return initialState;
    }
  },
  extraReducers: builder => {
    // fetchAuthRequestSpid
    builder.addCase(fetchAuthRequestSpid.pending, (state) => {
      state.status = StatusEnum.Loading;
      state.error = null;
    })
    builder.addCase(fetchAuthRequestSpid.rejected, (state, action) => {
      state.status = StatusEnum.Failed;
      state.error = (action.error.message) ? action.error.message : "";
    })
    builder.addCase(fetchAuthRequestSpid.fulfilled, (state, { payload }: PayloadAction<AuthRequest>) => {
      state.status = StatusEnum.Succeeded;
      state.error = null;
      state.authRequest = payload;
    })

    // fetchSpidProvidersList
    builder.addCase(fetchSpidProvidersList.pending, (state) => {
      state.status = StatusEnum.Loading;
      state.error = null;
    })
    builder.addCase(fetchSpidProvidersList.rejected, (state, action) => {
      state.status = StatusEnum.Failed;
      state.error = (action.error.message) ? action.error.message : "";
    })
    builder.addCase(fetchSpidProvidersList.fulfilled, (state, { payload }: PayloadAction<SpidProviders>) => {
      state.status = StatusEnum.Succeeded;
      state.error = null;
      state.spidProviders = payload;
    })
  }
})

export const { clear } = spidSlice.actions;
export default spidSlice.reducer;
import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

interface SnackBarProps {
    open:boolean,
    message: string,
    onClose: () => void
    success: boolean
}
export default function SnackBar({open, message, onClose, success}:SnackBarProps) {
    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {return;}
        onClose();
    };

    return (
        <Snackbar open={open} 
        autoHideDuration={5000} 
        onClose={handleClose} 
        anchorOrigin={{vertical:'bottom',horizontal: 'right'}}>
            <Alert onClose={handleClose} severity={success ? "success":"error"}>
                {message}    
            </Alert>
        </Snackbar>
    );
}

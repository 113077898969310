import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type Click = 'yes' | 'no' | null;
interface DialogState {
    open: boolean,
    title: string,
    text: string,
    click: Click
}

interface DialogsState {
    dialogState: DialogState
}

const initialState: DialogsState = {
    dialogState: {
        open: false,
        title: "",
        text: "",
        click: null
    }
}

export const dialogsSlice = createSlice({
    name: 'dialogs',
    initialState,
    reducers: {
        openDialog: state => {
            state.dialogState.open = true;
        },
        closeDialog: state => {
            state.dialogState.open = false;
            state.dialogState.title = "";
            state.dialogState.text = "";
        },
        openDialogWithMessage: (state, { payload }: PayloadAction<{ title: string, text: string }>) => {
            state.dialogState.open = true;
            state.dialogState.title = payload.title;
            state.dialogState.text = payload.text;
        },
        yesClickDialog: state => {
            state.dialogState.click = "yes";
        },
        noClickDialog: state => {
            state.dialogState.click = "no";
        },
        resetClickDialog: state => {
            state.dialogState.click = null;
        },
    }
})

export const {
    openDialog,
    closeDialog,
    openDialogWithMessage,
    yesClickDialog,
    noClickDialog,
    resetClickDialog
} = dialogsSlice.actions;

export default dialogsSlice.reducer
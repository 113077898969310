import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface uriToTitle {
  [uri:string]:string
}
interface RoutingState {
  title: string,
  uri: string,
  uritotitles: uriToTitle
}

// Define the initial state using that type
export const initialState: RoutingState = {
  title: '',
  uri: '',
  uritotitles: {}
}

export const routingSlice = createSlice({
  name: 'routing',
  initialState,
  reducers: {
    navigateTo: (state, {payload}: PayloadAction<string>) => {
      state.title = state.uritotitles["/"+payload.split("/")[1]];
      state.uri = payload;
    },
    setUriToTitle: (state, {payload}: PayloadAction<uriToTitle>) => {
      state.uritotitles = payload;
    }
  }
})

export const { navigateTo, setUriToTitle } = routingSlice.actions

export default routingSlice.reducer
import React, { useEffect } from 'react';
import { Route, RouteProps, useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { isLogged } from '../store/slices/authInfoSlice';
import { navigateTo } from '../store/slices/routingSlice';
import { loginPath } from '../utils/paths';

export const PrivateRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const logged = useAppSelector(isLogged);

  useEffect(() => {
    if (!logged) {
      history.push(loginPath);
      dispatch(navigateTo(loginPath));
    }
  }, [dispatch, history, logged]);

  return <Route {...rest} >{logged ? children : <></>}</Route>
}

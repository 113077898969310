export enum StatusEnum {
    Loading = "LOADING",
    Succeeded = "SUCCEEDED",
    Failed = "FAILED"
}

export type Language = 'it' | 'it-IT' | 'en' | 'en-US' | 'en-GB';
export enum LanguageEnum {
    IT = "it",
    EN = "en"
}

export enum NewUpdateEnum {
    NEW = "new",
    UPDATE = "upd",
    NONE = ""
}

export default interface SortChoice {
    sort: string,
    order: string
}

export interface ErrorResponse {
    data: string,
    codice: number,
    messaggio: string,
    message?: string
}

export interface SimpleResponse {
    code: string,
    message: string
}
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { NomeHost } from '../../models/NomeHostModels';
import { StatusEnum } from '../../models/Utils';
import { nomeHostInfoLoad } from '../../services/organization.service';

interface NomeHostInfoState {
  status: string,
  nomeHost: NomeHost | null,
  error: string | null
}

const initialState: NomeHostInfoState = {
  status: StatusEnum.Succeeded,
  nomeHost: null,
  error: null
}

export const fetchNomeHostInfo = createAsyncThunk('fetchNomeHostInfo', async () => {
  const response = await nomeHostInfoLoad();
  return response.data as NomeHost;
})

export const nomeHostSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
  },
  extraReducers: builder => {
    builder.addCase(fetchNomeHostInfo.pending, (state) => {
      state.status = StatusEnum.Loading;
    })
    builder.addCase(fetchNomeHostInfo.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.status = StatusEnum.Failed;
      state.nomeHost = null;
    })
    builder.addCase(fetchNomeHostInfo.fulfilled, (state, { payload }: PayloadAction<NomeHost>) => {
      state.status = StatusEnum.Succeeded;
      state.nomeHost = payload;
    })
  }
})

export default nomeHostSlice.reducer
import { configureStore } from '@reduxjs/toolkit'
import { applicationsSlice } from './slices/applicationsSlice';
import { authInfoSlice } from './slices/authInfoSlice';
import { snackbarsSlice } from './slices/snackBarSlice';
import { userSlice } from './slices/userSlice';
import { dialogsSlice } from './slices/dialogsSlice';
import { nomeHostSlice } from './slices/nomeHostSlice';
import { spidSlice } from './slices/spidSlice';
import { cieSlice } from './slices/cieSlice';

const store = configureStore({
  reducer: {
    authInfo: authInfoSlice.reducer,
    snackbars: snackbarsSlice.reducer,
    applications: applicationsSlice.reducer,
    user: userSlice.reducer,
    dialogs: dialogsSlice.reducer,
    nomeHost: nomeHostSlice.reducer,
    spid: spidSlice.reducer,
    cie: cieSlice.reducer,
  }
})
export default store;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
import Axios from "axios";
import { LoggedUserResetPasswordRequest, NotLoggedUserResetPasswordRequest } from "../models/UserModels";
import { SimpleResponse } from "../models/Utils";
import { portaleURL } from "../utils/utilconst";

export function loggeUserResetPassword(req:LoggedUserResetPasswordRequest, language:string) {
    return Axios.post<SimpleResponse>(portaleURL + '/utenti/modifica/resetpassword?lang='+language, req);
}

export function notLoggeUserResetPassword(req:NotLoggedUserResetPasswordRequest, language:string) {
    return Axios.post<SimpleResponse>(portaleURL + '/utenti/noauth/resetpassword?lang='+language, req);
}

export function userForgotPassword(email:string,language:string) {
    return Axios.get<SimpleResponse>(portaleURL + '/utenti/noauth/forgotpassword/'+email+'?lang='+language);
}
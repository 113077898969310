export function isToken2FA(token?: string | null) {
  if (token)
    return token.startsWith('2FA');
  return false;
}

export function capitalize(name: string) {
  return name.charAt(0).toUpperCase() + name.slice(1);
}

export function getDateGGMMYY(date: Date): string {
  return date.getFullYear() + '-' +
    ((date.getMonth() + 1) < 10 ? '0' : '') + (date.getMonth() + 1) + '-' +
    (date.getDate() < 10 ? '0' : '') + date.getDate();
}

export function setCookie(name: string, val: string, domain: string | undefined, expire?: number) {
  const date = new Date();
  const value = val;

  // Expire
  date.setTime(date.getTime() + (expire ?? 0));

  let newCookie = name + "=" + value + ";";
  if (expire) newCookie += " expires=" + date.toUTCString() + ";";
  if (window.location.protocol === "https:") newCookie = newCookie + " SameSite=Lax; Secure;";
  if (domain) newCookie = newCookie + " domain=" + domain + ";";
  document.cookie = newCookie;
}

export function getCookie(key: string) {
  const found = document.cookie.split(';').find(row => row.trim().startsWith(key + '='));
  return found ? found.split('=')[1] : null;
}

export function deleteCookie(key: string, domain: string | undefined) {
  let str = key + "= ; expires = Thu, 01 Jan 1970 00:00:00 GMT;";
  if (window.location.protocol === "https:") str = str + " SameSite=Lax; Secure;";
  if (domain) str = str + " domain=" + domain + ";";
  document.cookie = str;
}

export function emailIsValid(email: string) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

export function passwordIsValid(password: string) {
  return password.length > 7;
}
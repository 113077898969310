import React from 'react';
import { useInterceptor } from './useInterceptor';
import SnackBar from '../snackbar/SnackBar';
import { useAppSelector } from '../../store/hooks';

export const Interceptor: React.FC = () => {
  const sbErrorState = useAppSelector(state => state.snackbars.snackbarerror);
  const sbSuccessState = useAppSelector(state => state.snackbars.snackbarsuccess);

  const { onCloseError, onCloseSuccess } = useInterceptor();
  return <>
    <SnackBar open={sbErrorState.open} message={sbErrorState.message} onClose={onCloseError} success={false}/>
    <SnackBar open={sbSuccessState.open} message={sbSuccessState.message} onClose={onCloseSuccess} success={true}/>
  </>
};
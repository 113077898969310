import React, { useEffect, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { clearApplications, fetchApplications, getApplications, isLoadingApplications } from "../../store/slices/applicationsSlice";
import { Box, Button, Container, createMuiTheme, createStyles, Grid, makeStyles, Theme, ThemeProvider, Typography } from "@material-ui/core";
import LoadingSvg from "../svgs/LoadingSvg";
import { goToApplication } from "../../store/slices/authInfoSlice";
import clsx from 'clsx';
import DialogConfirm from "../components/dialogs/DialogConfirm";
import { openDialogWithMessage } from '../../store/slices/dialogsSlice';
import { getCookie } from "../../utils/utilfunctions";
import { Application } from "../../models/Application";
import { cookieRedirect } from "../../utils/utilconst";

const theme = createMuiTheme();
theme.typography.h5 = {
  fontSize: 'calc(12px + (18 - 12) * ((100vw - 300px) / (1600 - 300)));'
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appicon: {
      maxWidth: '100%',
      cursor: 'pointer',
      borderRadius: 10,
    },
    appiconNotAvailable: {
      filter: 'saturate(0) blur(1px)'
    },
    appiconAvailable: {
      '&:hover, &$focusVisible': {
        opacity: 0.7
      },
    },
    apptitle: {
      margin: 'auto',
      whiteSpace: 'nowrap',
      width: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      textAlign: 'center'
    },
    organizationLabel: {
      position: 'absolute',
      paddingLeft: 5,
      paddingRight: 5,
      backgroundColor: '#ffffffaa',
      boxShadow: '3px 3px 5px 3px #00000077, -3px -3px 5px 3px #ffffff77',
      fontWeight: 'bold',
      borderRadius: 5,
      top: 10,
      left: 10,
      zIndex: 1,
    },
    notAvailableContainer: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      zIndex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      alignItems: 'center',
    },
    notAvailableText: {
      textShadow: '4px 3px 6px rgba(255,255,255,0.75)',
      color: '#cc3300',
      fontWeight: 'bold',
      fontSize: 20,
      textAlign: 'center',
      backgroundColor: '#ffffff33',
    },
    moreInfoButton: {
      backgroundColor: '#ffffff33',
      borderColor: '#8F2400',
      fontWeight: 'bold',
      color: '#cc3300',
      textShadow: '4px 3px 6px rgba(255,255,255,0.75)'
    }
  })
);

const PageApplications = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { i18n, t } = useTranslation();

  const applications = useAppSelector(getApplications);
  const isLoadingApps = useAppSelector(isLoadingApplications);
  const utente = useAppSelector(state => state.applications.utente);
  const applicazioni = useAppSelector(state => state.applications.applications)

  const sortedApplications = useMemo(() => {
    let retval: Application[] = [];
    const applicationMap: Record<string, Application[]> = {};

    applications?.forEach(elem => {
      if (!applicationMap[elem.idOrganizzazione]) {
        applicationMap[elem.idOrganizzazione] = [];
      }
      applicationMap[elem.idOrganizzazione].push(elem);
    })

    Object.keys(applicationMap).forEach(key => {
      applicationMap[key]?.forEach(elem => retval.push(elem))
    })

    return retval;
  }, [applications]);

  useEffect(() => {
    dispatch(fetchApplications(i18n.language));
  }, [dispatch, i18n.language]);

  useEffect(() => {
    return () => {
      dispatch(clearApplications());
    };
  }, [dispatch]);

  const goToApp = useCallback((url: string | undefined, idAppSftw: string, idAppProfilo: string, appPathName: string, idOrganizzazione: number) => {
    const selectedApplication = applicazioni?.find(elem => elem.idOrganizzazione === idOrganizzazione && elem.idApplicazioneSoftware === idAppSftw && elem.idApplicazioneSoftwareProfilo === idAppProfilo)

    if (utente && selectedApplication) {
      dispatch(goToApplication({
        url: url,
        idOrg: (idOrganizzazione.toString() ?? ""),
        idProfil: [idAppSftw, idAppProfilo],
        appPathName: appPathName,
        utente,
        applicazione: selectedApplication,
      }));
    }
  }, [applicazioni, dispatch, utente])

  /**
   * Redirect automatico se c'è solo un'applicazione da login
   */
  const redirectCookie = getCookie(cookieRedirect);
  useEffect(() => {
    if (applications?.length === 1 && !applications[0].indisponibilitaMotivo && redirectCookie && redirectCookie.length > 0) {
      goToApp(
        applications[0].urlConnessione,
        applications[0].idApplicazioneSoftware,
        applications[0].idApplicazioneSoftwareProfilo,
        applications[0].appPathName,
        applications[0].idOrganizzazione,
      )
    }
  }, [applications, applications?.length, goToApp, redirectCookie]);

  return <>
    <DialogConfirm />
    <Container fixed>
      <ThemeProvider theme={theme}>
        {isLoadingApps ? <Grid container justify="center"><LoadingSvg color="primary" width={300} /></Grid> : (
          <Grid container spacing={4}>
            {
              sortedApplications?.length === 1 && !applications[0].indisponibilitaMotivo && redirectCookie && redirectCookie.length > 0   // mostra la pagina redirect
                ? <Grid
                  container
                  direction="column"
                  justify="center"
                  alignItems="center"
                >
                  <h1>
                    {t('redirectingTo') + ' ' + applications[0].descrizione + ' (' + applications[0].descrizioneProfilo + ')'}
                  </h1>
                </Grid>
                : sortedApplications.map(app => {
                  const isAvailable = !app.indisponibilitaMotivo;
                  const message = app.indisponibilitaMotivo;

                  return <Grid key={app.idApplicazioneSoftwareProfilo} item xs={12} sm={6} lg={3}>
                    <Grid container justify="center">
                      <Grid item xs={12}>
                        <div style={{ position: 'relative' }}>
                          <Typography className={clsx(classes.organizationLabel, { [classes.appiconNotAvailable]: !isAvailable })} variant='subtitle2'>{app.organizzazioneNome}</Typography>
                          {
                            !isAvailable &&
                            <div className={classes.notAvailableContainer}>
                              <Typography className={classes.notAvailableText}>
                                {t('notAvailable')}
                              </Typography>
                              <Box>
                                <Button
                                  onClick={() => {
                                    dispatch(openDialogWithMessage({ text: message, title: t('moreInfo') }))
                                  }}
                                  variant='outlined'
                                  className={classes.moreInfoButton}
                                >
                                  {t('moreInfo')}
                                </Button>
                              </Box>
                            </div>
                          }
                          <img
                            onClick={isAvailable
                              ? () => goToApp(app.urlConnessione, app.idApplicazioneSoftware, app.idApplicazioneSoftwareProfilo, app.appPathName, app.idOrganizzazione)
                              : undefined
                            }
                            className={clsx(classes.appicon, { [classes.appiconAvailable]: isAvailable, [classes.appiconNotAvailable]: !isAvailable })}
                            src={app.profiloIcona?.startsWith('data')
                              ? app.profiloIcona
                              : ![null, undefined, ''].includes(app.profiloIcona)
                                ? 'data:image/png;base64,' + app.profiloIcona
                                : app.icona ? app.icona : './assets/appicons/defaulticon.png'


                            }

                            alt="Application icon"
                          />
                        </div>
                      </Grid>
                      <Grid item>
                        <Box>
                          <Typography className={classes.apptitle} variant="h5">{app.descrizione}</Typography>
                          <Typography className={classes.apptitle} variant="h5">{"(" + app.descrizioneProfilo + ")"}</Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                })
            }
          </Grid>
        )}
      </ThemeProvider>
    </Container>
  </>
}
export default PageApplications;

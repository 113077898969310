export const orderAsc = 'asc';
export const orderDesc = 'desc';

export const homeTitle = 'HOME';
export const homePath = '/home';

export const refreshTokenTime = 900000;

export const cookiePortal = 'portalesaas';

export const cookieOrg = 'portaleorg';
export const cookieProfil = 'portaleprofil';
export const cookieHome = 'home';
export const cookieAppPathName = 'appPathName';
export const cookieRedirect = 'redirect';
export const cookieUser = 'utente';

export const cookieCheckTime = 3000; //in millisec

const domains = window.location.hostname.split(".");

//export const secondLevelDomain = (domains[domains.length-1]!=="localhost") ? domains[domains.length-2]+"."+domains[domains.length-1] : domains[domains.length-1];
export const secondLevelDomain = (domains[domains.length - 1] !== "localhost") ? domains[domains.length - 2] + "." + domains[domains.length - 1] : domains[domains.length - 1];

export const protocol = window.location.protocol + "//";

export const zuulUrl = ((process.env.REACT_APP_HTTPS === "true") ? "https://" : "http://") + ((secondLevelDomain !== "localhost") ? process.env.REACT_APP_API_THIRDLEVELDOMAIN + "." + secondLevelDomain : process.env.REACT_APP_API_THIRDLEVELDOMAIN + ".nousmeditaliatest.it" /* (secondLevelDomain+":"+process.env.REACT_APP_LOCALHOST_ZUUL_PORT) */);
// export const zuulUrl = protocol+(process.env.REACT_APP_API_THIRDLEVELDOMAIN+".consorzioinnovotest.it");

export const authURL = (process.env.REACT_APP_API_THIRDLEVELDOMAIN && process.env.REACT_APP_AUTH_PATH) ? zuulUrl + process.env.REACT_APP_AUTH_PATH : "";

export const portaleURL = (process.env.REACT_APP_API_THIRDLEVELDOMAIN && process.env.REACT_APP_PORTALE_PATH) ? zuulUrl + process.env.REACT_APP_PORTALE_PATH : "";
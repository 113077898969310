import { Button, Link, makeStyles, TextField, Typography } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { StatusEnum } from "../../models/Utils";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { forgotPassword } from "../../store/slices/userSlice";
import { loginPath } from "../../utils/paths";
import { emailIsValid } from "../../utils/utilfunctions";
import LeftImage from "../components/LeftImage/LeftImage";
import LoadingSvg from "../svgs/LoadingSvg";

const useStyles = makeStyles((theme) => ({
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    link: {
        cursor: "pointer"
    }
}));

const PageForgotPassword = () => {
    const classes = useStyles();
    const { i18n, t } = useTranslation();
    const [email, setEmail] = useState('');
    const history = useHistory();
    const dispatch = useAppDispatch();
    const statusForgot = useAppSelector(state => state.user.statusForgot);
    const errorMessage = useAppSelector(state => state.user.error);

    const [invalidEmail, setInvalidEmail] = useState<boolean>(false);
    const [invalidBackend, setInvalidBackend] = useState<boolean>(false);

    const [called, setCalled] = useState<boolean>(false);
    const [completed, setCompleted] = useState<boolean>(false);

    const sendForgotEmail = (event: React.SyntheticEvent) => {
        event.preventDefault();

        const emailValid = emailIsValid(email);
        if (!emailValid) setInvalidEmail(true);

        if (emailValid) {
            setInvalidEmail(false);
            setCalled(true);
            dispatch(forgotPassword({ email: email, lang: i18n.language }));
        }
    }

    useEffect(() => {
        if (called && statusForgot === StatusEnum.Succeeded) {
            setCompleted(true);
            setCalled(false);
        }else if(called && statusForgot === StatusEnum.Failed) {
            setInvalidBackend(true);
            setCompleted(false);
            setCalled(false);
        }
    }, [called, statusForgot]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        invalidBackend && setInvalidBackend(false);
        invalidEmail && setInvalidEmail(false);
        setEmail(event.target.value);
    };

    const sendDisabled = () => {
        return email.length === 0;
    }

    const clear = useCallback(() => {
        setInvalidBackend(false);
        setInvalidEmail(false);
        setEmail('');
        setCalled(false);
        setCompleted(false);
    }, []);

    useEffect(() => {
        return () => {
            clear()
        };
    }, [clear]);

    useEffect(() => {
        clear();
    }, [i18n.language, clear]);

    return (
        <LeftImage>
            {completed ? <Typography component="h1" variant="h5"> {t('forgotPasswordCompleted')} </Typography>
                :
                <>
                    <Typography component="h1" variant="h5">
                        {t('forgotPassword')}
                    </Typography>
                    <Typography component="h6">
                        {t('sendEmailMessage')}
                    </Typography>

                    <Typography component="h3" variant="h6" color="error">
                        {invalidBackend ? errorMessage : (invalidEmail && t('invalidEmail'))}&nbsp;
                    </Typography>

                    {statusForgot === StatusEnum.Loading ? <LoadingSvg color="primary" width={200} /> : (
                        <form className={classes.form} noValidate onSubmit={sendForgotEmail}>
                            <TextField
                                error={invalidEmail || invalidBackend}
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label={t('emailAddress')}
                                name="email"
                                autoFocus
                                value={email}
                                onChange={handleChange}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                disabled={sendDisabled()}
                            >
                                {t('send')}
                            </Button>

                            <Link onClick={() => history.push(loginPath)} variant="body2" className={classes.link}>
                                {t('backToSignin')}
                            </Link>
                        </form>
                    )}
                </>
            }
        </LeftImage>
    );
}
export default PageForgotPassword;
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Application } from '../../models/Application';
import { User } from '../../models/UserModels';
import { StatusEnum } from '../../models/Utils';
import { loadApplications } from '../../services/applications.service';
import { RootState } from '../store';

interface ApplicationsState {
    status: string,
    applications: Application[],
    utente: User | null,
    error: string | null
}

const initialState: ApplicationsState = {
    status: StatusEnum.Succeeded,
    applications: [],
    utente: null,
    error: null
}

export const fetchApplications = createAsyncThunk('categorie/fetchApplications',
    async (lang: string) => {
        const response = await loadApplications(lang);
        return [
            response.data.applicazioni as Application[],
            {
                idUtente: response.data.idUtente,
                nome: response.data.nome,
                cognome: response.data.cognome,
                nomeUtente: response.data.nomeUtente,
            }
        ];
    }
)

export const applicationsSlice = createSlice({
    name: 'applications',
    initialState,
    reducers: {
        clearApplications: state => {
            state.status = StatusEnum.Succeeded;
            state.applications = [];
            state.error = null;
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchApplications.pending, (state) => {
            state.status = StatusEnum.Loading;
        })
        builder.addCase(fetchApplications.rejected, (state, action) => {
            state.error = (action.error.message) ? action.error.message : "";
            state.status = StatusEnum.Failed;
            state.utente = null;
            state.applications = [];
        })
        builder.addCase(fetchApplications.fulfilled, (state, { payload }: PayloadAction<any[]>) => {
            state.status = StatusEnum.Succeeded;
            state.applications = payload[0];
            state.utente = payload[1];
            state.error = null;
        })
    }
})

export const { clearApplications } = applicationsSlice.actions

export const getApplications = (state: RootState) => state.applications.applications
export const isLoadingApplications = (state: RootState) => state.applications.status === StatusEnum.Loading

export default applicationsSlice.reducer
import { Button, Link, makeStyles, TextField, Typography } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { StatusEnum } from "../../models/Utils";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { resetPasswordNotLogged } from "../../store/slices/userSlice";
import { loginPath } from "../../utils/paths";
import { passwordIsValid } from "../../utils/utilfunctions";
import LeftImage from "../components/LeftImage/LeftImage";
import LoadingSvg from "../svgs/LoadingSvg";

const useStyles = makeStyles((theme) => ({
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1)
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    },
    loginbtn: {
        margin: theme.spacing(3, 0, 2)
    },
    link: {
        cursor: "pointer"
    }
}));

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const PageResetPassword = () => {
    const classes = useStyles();
    const { i18n, t } = useTranslation();
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const dispatch = useAppDispatch();
    const statusReset = useAppSelector(state => state.user.statusReset);
    const errorMessage = useAppSelector(state => state.user.error);
    const history = useHistory();
    const query = useQuery();

    const [invalidPassword, setInvalidPassword] = useState<boolean>(false);
    const [invalidCheckPassword, setInvalidCheckPassword] = useState<boolean>(false);
    const [invalidBackend, setInvalidBackend] = useState<boolean>(false);

    const [called, setCalled] = useState<boolean>(false);
    const [completed, setCompleted] = useState<boolean>(false);

    useEffect(() => {
        if (!query.get("token")) history.push(loginPath);
    }, [history, query]);

    const resetPwd = (event: React.SyntheticEvent) => {
        event.preventDefault();
        const pwdValid = passwordIsValid(newPassword);
        const checkPwdValid = (newPassword === confirmNewPassword);

        pwdValid ? setInvalidPassword(false) : setInvalidPassword(true);
        checkPwdValid ? setInvalidCheckPassword(false) : setInvalidCheckPassword(true);

        if (pwdValid && checkPwdValid) {
            const token = query.get("token");
            setCalled(true);
            token && dispatch(resetPasswordNotLogged({ req: { newPassword: newPassword, token: token }, lang: i18n.language }));
        }
    }

    useEffect(() => {
        if (called && statusReset === StatusEnum.Succeeded) {
            setCompleted(true);
            setCalled(false);
        } else if (called && statusReset === StatusEnum.Failed) {
            setInvalidBackend(true);
            setCompleted(false);
            setCalled(false);
        }
    }, [called, statusReset]);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        invalidBackend && setInvalidBackend(false);
        invalidPassword && setInvalidPassword(false);
        invalidCheckPassword && setInvalidCheckPassword(false);
        if (event.target.name === 'newpassword') setNewPassword(event.target.value);
        else setConfirmNewPassword(event.target.value);
    };

    const isResetDisabled = () => {
        return (confirmNewPassword.length === 0 || newPassword.length === 0);
    }

    const clear = useCallback(() => {
        setInvalidBackend(false);
        setNewPassword('');
        setConfirmNewPassword('');
        setInvalidPassword(false);
        setInvalidCheckPassword(false);
        setCalled(false);
        setCompleted(false);
    }, []);

    useEffect(() => {
        return () => {
            clear()
        };
    }, [clear]);

    useEffect(() => {
        clear();
    }, [i18n.language, clear]);

    return (
        <LeftImage>
            {completed
                ?
                <>
                    <Typography component="h1" variant="h5"> {t('resetPasswordCompleted')} </Typography>
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.loginbtn}
                        onClick={() => history.push(loginPath)}>
                        Login
                    </Button>
                </>
                :
                <>
                    <Typography component="h1" variant="h5">
                        {t('resetPassword')}
                    </Typography>

                    <Typography component="h3" variant="h6" color="error">
                        {invalidBackend ? errorMessage : (
                            invalidPassword ? t('invalidPassword') : (invalidCheckPassword && t('differentPasswords'))
                        )}&nbsp;
                    </Typography>

                    {statusReset === StatusEnum.Loading ? <LoadingSvg color="primary" width={200} /> : (
                        <form className={classes.form} noValidate onSubmit={resetPwd}>
                            <TextField
                                error={invalidBackend || invalidPassword}
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="newpassword"
                                label={t('newPassword')}
                                type="password"
                                id="newpassword"
                                autoFocus
                                value={newPassword}
                                onChange={handleInputChange}
                            />
                            <TextField
                                error={invalidBackend || invalidCheckPassword}
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="checkpassword"
                                label={t('confirmPassword')}
                                type="password"
                                id="checkpassword"
                                value={confirmNewPassword}
                                onChange={handleInputChange}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                disabled={isResetDisabled()}
                            >
                                {t('resetPassword')}
                            </Button>

                            <Link onClick={() => history.push(loginPath)} variant="body2" className={classes.link}>
                                {t('backToSignin')}
                            </Link>
                        </form>
                    )}
                </>
            }
        </LeftImage>
    );
}
export default PageResetPassword;
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Grid, IconButton, Menu, MenuItem } from '@material-ui/core';
import LanguageToggle from './LanguageToggle';
import React from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { isLogged, logoutAction } from '../../store/slices/authInfoSlice';
import { AccountCircle } from '@material-ui/icons';
import { openDialog } from '../../store/slices/dialogsSlice';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            flexGrow: 1,
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        drawerHeader: {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
            justifyContent: 'flex-end'
        }
    }),
);

const LanguageAppBar = () => {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const logged = useAppSelector(isLogged);
    const { t } = useTranslation();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const logoutClick = () => {
        dispatch(logoutAction());
        setAnchorEl(null);
    };

    const resetPwdClick = () => {
        dispatch(openDialog());
        setAnchorEl(null);
    }

    return (
        <>
            <div className={classes.root}>
                <AppBar position="static">
                    <Toolbar>
                        <Grid container justify="space-between" alignItems="center">
                            {logged && (
                                <div>
                                    <IconButton
                                        aria-label="account of current user"
                                        aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        onClick={handleMenu}
                                        color="inherit"
                                    >
                                        <AccountCircle />
                                    </IconButton>
                                    <Menu
                                        id="menu-appbar"
                                        anchorEl={anchorEl}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        open={open}
                                        onClose={handleClose}
                                    >
                                        <MenuItem onClick={resetPwdClick}>{t('resetPassword')}</MenuItem>
                                        <MenuItem onClick={logoutClick}>Logout</MenuItem>
                                    </Menu>
                                </div>
                            )}
                            <Grid item>
                                <LanguageToggle />
                            </Grid>
                        </Grid>

                    </Toolbar>
                </AppBar>
            </div>
            <div className={classes.drawerHeader} />
        </>
    );
}
export default LanguageAppBar;
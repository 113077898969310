import { Box, createMuiTheme, CssBaseline, responsiveFontSizes, ThemeProvider } from "@material-ui/core";
import React, { Suspense } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import AppLoad from "./core/AppLoad";
import DialogLoggedResetPassword from "./core/components/dialogs/DialogLoggedResetPassword";
import LanguageAppBar from "./core/components/LanguageAppBar";
import { Interceptor } from "./core/interceptor/Interceptor";
import PageApplications from "./core/pages/PageApplications";
import PageLogin from "./core/pages/PageLogin";
import PageNotFound from "./core/pages/PageNotFound";
import { PrivateRoute } from "./core/PrivateRoute";
import LoadingSvg from "./core/svgs/LoadingSvg";
import themes from "./themes/themes";
import { applicationsPath, resetPasswordPath, loginPath, forgotPasswordPath } from "./utils/paths";
import PageResetPassword from "./core/pages/PageResetPassword";
import PageForgotPassword from "./core/pages/PageForgotPassword";
import { useAppSelector } from "./store/hooks";

function App() {
  const nomeHost = useAppSelector(state => state.nomeHost.nomeHost);
  return (
    <Box style={{ minHeight: '100vh' }}>
      <AppLoad />
      {nomeHost &&
        <ThemeProvider theme={responsiveFontSizes(createMuiTheme(themes[nomeHost.skinTheme]))}>
          <CssBaseline />
          <Interceptor />
          <BrowserRouter>
            <Switch>
              <Route path={loginPath} exact>
                <Suspense fallback={<LoadingSvg color="primary" width={300} />}>
                  <PageLogin />
                </Suspense>
              </Route>
              <Route path={forgotPasswordPath} exact>
                <Suspense fallback={<LoadingSvg color="primary" width={300} />}>
                  <PageForgotPassword />
                </Suspense>
              </Route>
              <Route path={resetPasswordPath} exact>
                <Suspense fallback={<LoadingSvg color="primary" width={300} />}>
                  <PageResetPassword />
                </Suspense>
              </Route>
              <PrivateRoute path={applicationsPath} exact>
                <Suspense fallback={<LoadingSvg color="primary" width={300} />}>
                  <LanguageAppBar />
                  <PageApplications />
                  <DialogLoggedResetPassword />
                </Suspense>
              </PrivateRoute>
              <Route path="/notfound">
                <Suspense fallback={<LoadingSvg color="primary" width={300} />}>
                  <PageNotFound />
                </Suspense>
              </Route>
              <Route path="/" exact><Redirect to={applicationsPath} /></Route>
              <Route path="*"><Redirect to="/notfound" /></Route>
            </Switch>
          </BrowserRouter>
        </ThemeProvider>
      }
    </Box>
  );
}

export default App;
